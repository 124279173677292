import vacations from './vacations';
import home from './home';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [home, vacations]
};

export default menuItems;
