// assets
import { IconWall, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';
import KayakingIcon from '@mui/icons-material/Kayaking';

// constant
const icons = {
    IconWall,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc
};

// ==============================|| THE WALL MENU ITEMS ||============================== //

const vacations = {
    id: 'vacations',
    title: 'Vacations',
    type: 'group',
    children: [
        {
            id: 'theWall',
            title: 'Bring On The Wall',
            type: 'collapse',
            icon: icons.IconWall,
            children: [
                {
                    id: 'part1',
                    title: "Hadrian's Wall",
                    type: 'item',
                    url: '/vacation/1',
                    breadcrumbs: false
                },
                {
                    id: 'part2',
                    title: 'The Cotswolds',
                    type: 'item',
                    url: '/vacation/2',
                    breadcrumbs: false
                },
                {
                    id: 'part3',
                    title: 'El Camino',
                    type: 'item',
                    url: '/vacation/3',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'canada',
            title: 'Canada',
            type: 'item',
            icon: KayakingIcon,
            url: '/vacation/4',
            breadcrumbs: false
        }
    ]
};

export default vacations;
