// assets
import { IconHome } from '@tabler/icons';

// ==============================|| THE WALL MENU ITEMS ||============================== //

const home = {
    id: 'home',
    type: 'group',
    children: [
        {
            id: 'user-home',
            title: 'Overview',
            type: 'item',
            url: '/',
            icon: IconHome,
            breadcrumbs: false
        }
    ]
};

export default home;
