import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const HomePage = Loadable(lazy(() => import('views/pages/home')));
const VacationPage = Loadable(lazy(() => import('views/pages/vacation')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        // <AuthGuard>
        //     <MainLayout />
        // </AuthGuard>
        <MainLayout />
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/vacation',
            element: <VacationPage />,
            children: [{ path: ':id', element: <VacationPage /> }]
        }
    ]
};

export default MainRoutes;
